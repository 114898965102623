
@mixin ellipse1 {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.annual-meeting-detail-page {
    .annual-meeting-detail {
        .banner {
            width: 100%;
            height: 307px;
            background: url(../assets/img/annual-metting/bg-review-banner.png);
            background-size: 100% 100%;
            box-shadow: 0 0px 10px rgba(45, 50, 55, 0.2);
            h4 {
                // padding-top: 90px;
                padding-top: 75px;
                font-size: 36px;
                font-family: Source Han Serif CN;
                font-weight: bold;
                color: #ffffff;
                line-height: 35px;
                text-shadow: 0px 2px 4px rgba(156, 7, 23, 0.9);
                margin-bottom: 42px;
                text-align: center;
            }
            h5 {
                text-align: center;
                font-size: 46px;
                font-family: Source Han Serif CN;
                font-weight: bold;
                color: #ffffff;
                line-height: 46px;
                text-shadow: 0px 2px 4px rgba(156, 7, 23, 0.9);
            }
            h6 {
                width: 140px;
                font-size: 16px;
                line-height: 31px;
                border: 1px solid #fff;
                color: #fff;
                background: rgba(255, 255, 255, 0);
                margin: 0 auto;
                border-radius: 20px;
                text-align: center;
                margin-top: 18px;
                cursor: pointer;
            }
        }
        .crumbs {
            h6 {
                width: 1200px;
                margin: 0 auto;
                font-size: 15px;
                line-height: 20px;
                margin-top: 14px;
                color: #2b221b;
                cursor: pointer;
                // #2b221b
            }
        }
    }
    .com-wrap {
        img {
            height: 93px;
            width: auto;
            margin: 45px auto;
            display: block;
        }
    }

    .summary {
        p {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2b221b;
            line-height: 29px;
            text-indent: 36px;
            margin-bottom: 20px;
            margin-top: -10px;
        }
        .video-wrap {
            video {
                width: 100%;
                height: 480px;
                // background: url(../assets/img/annual-metting/bg-review-banner.png) no-repeat;
                background-size: 100% 100%;
            }
        }
    }
    .agenda-review {
        // .fl {
        //     float: left;
        // }

        // .fr {
        //     float: right;
        // }

        .clear {
            clear: both;
        }

        .time-line {
            width: 1184px;
            margin: 0 auto;
            position: relative;
        }

        .time-line .left-btn,
        .time-line .right-btn {
            width: 20px;
            height: 20px;
            background: url(../assets/img/time-axis/Icon_Arrow_Left@2x.png) no-repeat;
            background-size: 14px 14px;
            background-position: 2px 3px;
            border: 2px solid #e7d5ba;
            border-radius: 50%;
            position: absolute;
            margin-top: -15px;
            cursor: pointer;
        }

        .time-line .left-btn {
            left: 0;
            top: 30px;
        }

        .time-line .right-btn {
            right: 0;
            top: 30px;
            transform: rotate(180deg);
        }

        // .time-line .right-btn:hover {
        //     background: url(../assets/img/time-axis/arrow-gray.png) no-repeat;
        //     // background-color: rgba(9, 92, 184, 1);
        //     transform: rotate(180deg);
        //     background-size: 16px 16px;
        //     background-position: 10px 10px;
        // }

        // .time-line .left-btn:hover {
        //     background: url(../assets/img/time-axis/arrow-gray.png) no-repeat;
        //     // background-color: rgba(9, 92, 184, 1);
        //     background-size: 16px 16px;
        //     background-position: 10px 10px;
        // }

        .time-line .time-box {
            width: 1048px;
            height: 176px;
            margin: 0 auto;
            overflow: hidden;
            padding-top: 20px;
        }

        .time-line .time-box ul {
            // overflow: hidden;
            position: relative;
            // height: 276px;
            padding-bottom: 34px;
        }

        .time-line .time-box ul:before {
            content: '';
            /* background: #d2d2d2; */
            height: 1px;
            width: 100%;
            position: absolute;
            left: 0;
            top: 6px;
            // background: url(../assets/img/time-axis/line.png) repeat-x;
            border-bottom: 1px solid #e7d5ba;
            background-position: left 50%;
            background-size: 1048px;
        }

        .time-line .time-box li {
            width: 262px;
            height: 122px;
            box-sizing: border-box;
            float: left;
            box-sizing: border-box;
            /* border: 1px solid #d2d2d2; */
            position: relative;
            cursor: pointer;
        }
        .time-line .time-box li .point-wrap {
            width: 240px;
            margin: 0 auto;
            .point {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                position: relative;
                background: url(../assets/img/time-axis/circle.png) no-repeat;
                background-size: 100% 100%;
                margin: 0 auto;
            }
        }
        .time-line .time-box li.active-review .point-wrap {
            .point {
                background: url(../assets/img/time-axis/point-hover.png) no-repeat;
                background-size: 100% 100%;
            }
        }

        .time-line .time-box li {
            position: relative;
            .date {
                position: absolute;
                top: -19px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 16px;
                font-family: Source Han Serif CN;
                font-weight: bold;
                color: #2b221b;
                line-height: 16px;
                text-align: center;
            }
        }
        .info {
            width: 240px;
            margin: 0 auto;
            .time,
            .history-title {
                font-size: 16px;
                font-family: Source Han Serif CN;
                font-weight: bold;
                color: #2b221b;
                line-height: 16px;
                text-align: center;
                display: block;
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .history-title {
                margin-top: 6px;
                margin-bottom: 18px;
            }
            a.abstract {
                display: block;
                text-align: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #6c6c6c;
                line-height: 22px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;

                // cursor: auto;
            }
        }

        // .time-line .time-box li:nth-child(2n) {
        //     margin-top: 154px;
        // }

        // .time-line .time-box li .fl {
        //     width: 16px;
        //     height: 122px;
        //     border-radius: 50%;
        //     box-sizing: border-box;
        //     margin-left: 15px;
        // }

        // .time-line .time-box li .fl .point {
        //     width: 16px;
        //     height: 16px;
        //     border-radius: 50%;
        //     position: relative;
        //     background: url(../assets/img/time-axis/circle.png) no-repeat;
        //     background-size: 100% 100%;
        // }

        // .time-line .time-box li div p {
        //     width: 224px;
        // }

        // .time-line .time-box li .fl .line {
        //     width: 1px;
        //     margin-left: 8px;
        //     height: 106px;
        //     border-left: 1px dashed rgba(9, 92, 184, 0.14);
        // }

        // .time-line .time-box li:nth-child(2n + 2) .fl {
        //     transform: rotate(180deg);
        // }

        // .time-line .time-box li:nth-child(2n + 1) .fr p {
        //     margin-top: -4px;
        // }

        // .time-line .time-box li:nth-child(2n + 2) .fr p {
        //     margin-top: 20px;
        // }

        // .time-line .time-box li .fr p {
        //     line-height: 26px;
        //     margin-left: 7px;
        //     color: #333;
        //     font-size: 14px;
        //     display: -webkit-box;
        //     overflow: hidden;
        //     white-space: normal !important;
        //     text-overflow: ellipsis;
        //     word-wrap: break-word;
        //     -webkit-line-clamp: 4;
        //     -webkit-box-orient: vertical;
        // }

        // .time-line .time-box li .fr p {
        //     a {
        //         cursor: default;
        //     }
        // }

        // .time-line .time-box li:nth-child(2n + 1) h3 {
        //     width: 262px;
        //     line-height: 32px;
        //     text-align: center;
        //     color: #fff;
        //     font-size: 14px;
        //     overflow: hidden;
        //     white-space: nowrap;
        //     text-overflow: ellipsis;
        // }

        // .time-line .time-box li:nth-child(2n + 2) {
        //     position: relative;
        // }

        // .time-line .time-box li:nth-child(2n + 2) h3 {
        //     position: absolute;
        //     top: -32px;
        //     left: 0;
        //     width: 262px;
        //     line-height: 32px;
        //     text-align: center;
        //     color: #fff;
        //     font-size: 14px;
        //     overflow: hidden;
        //     white-space: nowrap;
        //     text-overflow: ellipsis;
        // }

        .time-line .time-box li:hover:after,
        .time-line .time-box li.active:after {
            background: url(../assets/img/time-axis/point-hover.png) no-repeat;
        }
    }
    .swiper-wrap {
        width: 100%;
        height: 372px;
        background: #f3f1f1;
        padding-top: 39px;
        box-sizing: border-box;
        .el-carousel__item h3 {
            color: #475669;
            font-size: 18px;
            opacity: 0.75;
            line-height: 300px;
            margin: 0;
        }

        // .el-carousel__item:nth-child(2n) {
        //     background-color: #99a9bf;
        // }

        // .el-carousel__item:nth-child(2n + 1) {
        //     background-color: #d3dce6;
        // }
    }
    .books {
        ul {
            height: 407px;
            display: flex;
            margin-bottom: 37px;
            position: relative;
            z-index: 0;
            li {
                margin-right: 30px;

                img {
                    display: block;
                    width: 216px;
                    height: 307px;
                    box-shadow: 0 14px 10px -7px rgba(51, 51, 51, 0.3);
                    margin-bottom: 74px;
                    background-color: #fff;
                    cursor: pointer;
                }
                a {
                    display: block;
                    width: 216px;
                    text-align: center;
                    color: #0d1020;
                    font-size: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                }
            }
            li:last-child {
                margin-right: 0;
            }
        }
        ul::before {
            content: '';
            width: 1377px;
            height: 116px;
            background: url(../assets/img/annual-metting/img-bookshelf.png);
            background-size: 100% 100%;
            position: absolute;
            left: -88px;
            bottom: 0;
            z-index: -1;
        }
        ul:last-child {
            margin-bottom: 0;
        }
    }
    .media-report {
        width: 100%;
        height: 448px;
        // background: url(../assets/img/annual-metting/bg-news.jpg);
        background: url(../assets/img/annual-metting/bg-news4.png);
        background-repeat: repeat-x;
        background-size: auto 100%;
        margin-bottom: 80px;
        position: relative;
        z-index: 0;
        #reportBg {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            z-index: 0;
        }
        .media-report-content {
            height: 100%;
            background: rgba(13, 16, 32, 0.5);
            // padding: 19px 30px 19px 20px;
            padding: 19px 25px;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            ul {
                height: 364px;
                li {
                    width: 100%;
                    height: 51px;
                    line-height: 51px;
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);

                    a {
                        width: 850px;
                        height: 51px;
                        line-height: 51px;
                        display: block;
                        position: relative;
                        padding-left: 17px;
                        color: #fff;
                        font-size: 16px;

                        cursor: pointer;
                        @include ellipse1();
                    }
                    a::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 6px;
                        height: 6px;
                        background: #ffffff;
                        border-radius: 50%;
                    }
                    span {
                        display: block;
                        font-size: 14px;
                        color: #fff;
                    }
                }
                li:nth-child(2n + 2) {
                    margin-right: 0;
                }
            }
        }
    }
    .memory-wrap {
        margin-bottom: 80px;
    }

   
}
 .word-clould-wrap .wordcloud{
    width: 100%;
    padding: 55px 0;
    box-sizing: border-box;
    background: rgba(245, 243, 243, 0.5);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
        img {
            width: 1100px;
            display: block;
            // height: 200px;
        }
        
    }
