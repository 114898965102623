
.memory-swiper-img-component {
    margin-top: 14px;
    height: 296px;
    width: 100%;
    position: relative;
    #demo8 {
        overflow: hidden;
        height: 100%;
        width: 100%;
        color: #ffffff;
        /* margin: 0 auto; */
        position: absolute;
        top: 0;
        left: 0;
        // transform: translate(-50%, -50%);
    }
    table,
    tbody {
        margin: 0;
        padding: 0;
    }
    tr {
        margin: 0;
        padding: 0;
    }
    td {
        padding: 0;
    }
}
