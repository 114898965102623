
/* * {
    margin: 0;
    padding: 0;
    border: none;
    font-family: 微软雅黑;
}

html,
body {
    width: 100%;
    height: 100%;
    overflow: hidden;
} */

.psVideo {
    width: 1200px;
    position: relative;
    /* margin: 20px auto; */
    text-align: center;
    transition: all 0.5s;
    overflow: hidden;
}

.my-psVideo {
    position: relative;
    margin: 0 auto;
    width: 1200px;
    height: 480px;
    cursor: pointer;
    transition: all 0.5s;
    background: black;
}

.full {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: black;
}

// .psVideo-play-one {
//     width: 70px;
//     height: 70px;
//     background: url('../assets/img/annual-metting/video/playone.png') no-repeat;
//     opacity: 0.7;
//     background-size: cover;
//     position: absolute;
//     bottom: 70px;
//     right: 60px;
//     cursor: pointer;
// }

.psVideo-play-footer {
    position: absolute;
    width: 100%;
    height: 36px;
    bottom: 0;
}

.psVideo-shade {
    position: absolute;
    height: 60px;
    width: 100%;
    bottom: 4px;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    transition: opacity 0.3s;
}

.psVideo-shade-full {
    bottom: 0;
}

.psVideo-shade-on {
    opacity: 1;
}

.psVideo-shade-off {
    opacity: 0;
}

.psVideo-play-footer .psVideo-play-btn {
    width: 23px;
    height: 23px;
    float: left;
    margin-left: 3%;
    cursor: pointer;
    z-index: 3;
}

.psVideo-stop {
    background: url('../assets/img/annual-metting/video/stop.png') no-repeat;
    background-size: cover;
}

.psVideo-play {
    background: url('../assets/img/annual-metting/video/play.png') no-repeat;
    background-size: cover;
}

.psVideo-time {
    float: left;
    font-size: 15px;
    line-height: 23px;
    color: white;
    margin-left: 10px;
    z-index: 2;
}

.psVideo-dan {
    position: absolute;
    margin-left: 230px;
    margin-top: -1px;
    z-index: 10;
    transition: all 0.5s;
}

.psVideo-dan-input {
    height: 25px;
    background: rgba(245, 245, 245, 0.8);
    line-height: 25px;
    width: 200px;
    float: left;
    border-right: 1px solid cadetblue;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    outline: none;
    text-indent: 2px;
}

.psVideo-dan-btn {
    display: inline-block;
    height: 25px;
    font-size: 15px;
    width: 50px;
    text-align: center;
    line-height: 25px;
    float: left;
    color: #454545;
    font-weight: bold;
    background: rgba(245, 245, 245, 0.9);
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
}

.psVideo-dan-value {
    position: absolute;
    z-index: 20;
    color: white;
    font-size: 25px;
    font-weight: bold;
    white-space: nowrap;
    animation: danOnFull 7s linear;
    transform: translateX(-100%);
}

.psVideo-dan-value-full {
    position: absolute;
    z-index: 20;
    color: white;
    font-size: 25px;
    font-weight: bold;
    white-space: nowrap;
    animation: danOnFull 10s linear;
    transform: translateX(-100%);
}

@keyframes danOn {
    from {
        left: 100%;
        transform: translateX(0);
    }
    to {
        left: 0;
        transform: translateX(-100%);
    }
}

@keyframes danOnFull {
    from {
        left: 100%;
        transform: translateX(0);
    }
    to {
        left: 0;
        transform: translateX(-100%);
    }
}

.psVideo-dan-input-full {
    width: 300px;
}

.psVideo-dan-full {
    margin-left: calc(50% - 175px);
}

.psVideo-right-btn {
    float: right;
    margin-right: 3%;
    z-index: 4;
}

.psVideo-btn {
    width: 23px;
    height: 23px;
    float: right;
    margin-left: 20px;
    cursor: pointer;
}

.all {
    background: url('../assets/img/annual-metting/video/all.png') no-repeat;
    background-size: cover;
}

.loop {
    background: url('../assets/img/annual-metting/video/loop.png') no-repeat;
    background-size: cover;
}

.loop-ture {
    background: url('../assets/img/annual-metting/video/1loop.png');
    background-size: cover;
}

.set {
    position: relative;
    background: url('../assets/img/annual-metting/video/set.png') no-repeat;
    background-size: cover;
}

.set-list {
    display: none;
    background: black;
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: 40px;
    left: -85px;
    opacity: 0.7;
    border-radius: 4px;
}

.konge1 {
    display: none;
    position: absolute;
    width: 200px;
    height: 17px;
    bottom: 23px;
    left: -85px;
    text-align: center;
    z-index: 4;
}

.set:hover .set-list,
.set:hover .konge1 {
    display: block;
}

.play-speed {
    color: white;
    font-weight: bolder;
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
    text-align: center;
}

// .play-speed-list {
//     padding-top: 5px;
//     font-weight: bolder;
//     font-size: 17px;
//     color: azure;
//     text-align: center;
// }

// .play-speed-list span {
//     margin-left: 15px;
//     margin-right: 15px;
// }

// .play-speed-list span:hover {
//     color: #7969ff;
// }

.moren {
    color: #7969ff;
}

.huazhi {
    width: 50px;
    font-size: 15px;
    line-height: 23px;
    color: white;
}

.sound {
    position: relative;
    background: url('../assets/img/annual-metting/video/sound.png') no-repeat;
    background-size: cover;
}

.download {
    position: relative;
    background: url('../assets/img/annual-metting/video/down-btn.png') no-repeat;
    background-size: cover;
    opacity: 0.8;
}

.jinyin {
    position: relative;
    background: url('../assets/img/annual-metting/video/jinyin.png') no-repeat;
    background-size: cover;
}

.psVideo-progress {
    width: 95%;
    background: white;
    height: 2px;
    position: absolute;
    bottom: 50px;
    left: 2.5%;
    cursor: pointer;
    transition: bottom 0.1s;
    border-radius: 3px;
}

.psVideo-progress:hover {
    height: 6px;
    bottom: 48px;
}

.psVideo-progress .psVideo-timeBar {
    height: 100%;
    position: absolute;
    top: 0;
    display: block;
    z-index: 2;
    width: 0;
    background: #4977b5;
    // #f9d43a;
    // #4977B5;
    border-radius: 3px;
}

#soundBtn:hover .sound-list,
#soundBtn:hover .konge {
    display: block;
}

.sound-list {
    display: none;
    position: absolute;
    width: 30px;
    height: 85px;
    bottom: 40px;
    left: -6px;
    text-align: center;
    background: black;
    border-radius: 3px;
}

.konge {
    display: none;
    position: absolute;
    width: 30px;
    height: 17px;
    bottom: 23px;
    left: -6px;
    text-align: center;
    z-index: 4;
}

.sound-number {
    position: relative;
    width: 100%;
    text-align: center;
    height: 20px;
    padding-top: 5px;
    line-height: 20px;
    font-size: 15px;
    color: white;
}

.volume {
    position: relative;
    margin: auto;
    margin-top: 5px;
    width: 7px;
    height: 50px;
    background: #4977b5;
    // aqua;
    border-radius: 2px;
    overflow: hidden;
}

.volumeBar {
    display: block;
    width: 100%;
    height: 40px;
    position: absolute;
    background-color: white;
    z-index: 2;
}

.player-list {
    position: absolute;
    padding-left: 10px;
    width: 30px;
    height: 60px;
    top: 50%;
    margin-top: -30px;
    background: url('../assets/img/annual-metting/video/list.png') -10px 0px rgba(0, 0, 0, 0.1);
    background-size: cover;
    transition: opacity 0.3s;
}

.player-list-on {
    opacity: 1;
}

.player-list-off {
    opacity: 0;
}

.player-list-bk {
    display: none;
    position: absolute;
    width: 160px;
    height: 250px;
    top: 50%;
    margin-top: -150px;
    background: rgba(0, 0, 0, 0.3);
}

.player-list:hover {
    background: none;
}

.player-list:hover .player-list-bk {
    display: block;
}

.player-list-head {
    width: 100%;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 25px;
    color: white;
    background: #7c7d7f;
}

.player-list-cotent {
    height: 220px;
    width: 160px;
    overflow: hidden;
}

// .player-list-box {
//     height: 220px;
//     width: 195px;
//     text-align: center;
//     overflow: auto;
// }

// .player-list-video {
//     padding-top: 5px;
//     margin-left: -10px;
//     width: 100%;
//     height: 20px;
//     text-align: center;
//     line-height: 15px;
//     font-size: 16px;
//     color: #00f7de;
//     cursor: pointer;
//     font-weight: bolder;
//     border-bottom: 1px #60bff2 dashed;
//     transition: all 0.2s;
// }

// .player-list-video:hover {
//     height: 25px;
//     font-size: 18px;
//     line-height: 20px;
// }

.video-now,
.video-now:hover {
    height: 30px;
    font-size: 20px;
    line-height: 25px;
    background: rgba(139, 288, 255, 0.3);
    color: #6956ff;
}

footer {
    width: 100%;
    height: 30px;
    text-align: center;
    color: darkcyan;
    font-size: 20px;
    line-height: 30px;
    margin-top: 10px;
    top: 0;
}

.psVideo-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -35px;
    width: 70px;
    height: 60px;
    z-index: 30;
}

.psVideo-loading span {
    display: inline-block;
    width: 10px;
    height: 0;
    position: absolute;
    background: rgba(96, 191, 242, 0.53);
    top: 50%;
    margin-top: 0;
    left: 0;
}

.psVideo-loading span:nth-child(1) {
    animation: span1 1s linear infinite;
}

.psVideo-loading span:nth-child(2) {
    height: 30px;
    top: 50%;
    margin-top: -15px;
    margin-left: 20px;
    animation: span2 1s linear infinite;
}

.psVideo-loading span:nth-child(3) {
    height: 60px;
    top: 50%;
    margin-top: -30px;
    margin-left: 40px;
    animation: span3 1s linear infinite;
}

@keyframes span1 {
    0% {
        height: 0;
        margin-top: 0;
    }
    50% {
        height: 50px;
        margin-top: -25px;
    }
    100% {
        height: 0;
        margin-top: 0;
    }
}

@keyframes span2 {
    0% {
        height: 30px;
        margin-top: -15px;
    }
    25% {
        height: 60px;
        margin-top: -30px;
    }
    50% {
        height: 30px;
        margin-top: -15px;
    }
    75% {
        height: 0;
        margin-top: 0;
    }
    100% {
        height: 30px;
        margin-top: -15px;
    }
}

@keyframes span3 {
    0% {
        height: 60px;
        margin-top: -30px;
    }
    50% {
        height: 0;
        margin-top: 0;
    }
    100% {
        height: 60px;
        margin-top: -30px;
    }
}
